import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { CommunityFavicon } from './components/community/CommunityFavicon';
import indexRoutes from './routes';
import AnalyticService from './services/AnalyticsService';
import { persistor, store } from './store/configureStore';
import CustomRoute from './routes/CustomRoute';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Moengage from '@moengage/web-sdk';

function App({ setPage }) {
  async function init() {
    try {
      Moengage.initialize({
        app_id: process.env.REACT_APP_MOENGAGE_ID,
        debug_logs: process.env.REACT_APP_ENV === 'production' ? 0 : 1,
      });
      new AnalyticService();
      branch.init(`${process.env.REACT_APP_BRANCH_KEY}`, (err, data) => {
        if (err) {
          console.log('\n\nBranch Init Err: ', err);
          return;
        }
        const params = JSON.parse(data?.data);
        console.log('\n\nParams: ', params);

        if (params && params['+clicked_branch_link']) {
          const { route, communityId } = params;
          console.log('\n\ndata.data_parsed;: ', data.data_parsed);

          // if (params['$desktop_url']) window.location.href = params['$desktop_url'];
        }
      });
    } catch (error) {
      if (error.response && error.response.status && error.response.status == 503) {
        setPage('maintenance');
      }
    }
  }
  useEffect(() => {
    AOS.init({ duration: 1200, delay: 500 });
    AOS.refresh();
    init();
  }, []);

  return (
    <Router >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Switch>
            {/* <Redirect exact from="/" to="/login" /> */}
            {indexRoutes.map((route, key) => (
              <CustomRoute key={key} {...route} />
            ))}
          </Switch >
          <ToastContainer
            autoClose={3000}
            position="bottom-center"
            hideProgressBar
            pauseOnFocusLoss={false}
            closeButton={false}
            closeOnClick
            draggable={false}
            limit={3}
          />
          <CommunityFavicon />
        </PersistGate>
      </Provider>
    </Router>
  );
}

export default App;
